import React, { Fragment, useEffect } from 'react'
import CarouselComponent from './layout/carousel'

import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getRecipes, clearErrors as clearRecipeErrors } from '../actions/recipeActions'
import { getCourses, clearErrors as clearCourseErrors} from '../actions/courseActions'
import { getReviews, clearErrors as clearReviewErrors } from '../actions/reviewActions'
import { getProducts, clearErrors as clearProductErrors } from '../actions/productActions'
import { getPromos, clearErrors as clearPromoErrors } from '../actions/promoActions'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Metadata from './layout/metadata'
import YouTubeVideo from './layout/youTubeVideo'

import { initGA, logPageView } from '../GoogleAnalytics';

import CourseCard from './academy/courseCard'
import ProductCard from './shop/productCard'

const recipient = 'omoyecooks@gmail.com';
const subject = 'Work with Omoye';
const body = 'Hi Omoye,\nI would love to work with you!';

const Home = () => {
    const dispatch = useDispatch();

    let { loading: recipeLoading, error: recipeError, recipes } = useSelector(state => state.getRecipes);
    let { loading: courseLoading, error: courseError, courses } = useSelector(state => state.getCourses);
    let { loading: productLoading, error: productError, products } = useSelector(state => state.getProducts);
    const { loading: reviewLoading, error: reviewError, reviews } = useSelector(state => state.getReviews);
    const { error: promoError, promos } = useSelector(state => state.getPromos);
  
    const activePromotions = promos.filter(promo => new Date() >= new Date(promo.startDate) && new Date() <= new Date(promo.endDate))
    
    reviews.sort((a, b) => b.rating - a.rating);
    const courseReviews = reviews.filter(review => review.type === "course")

    recipes = recipes.slice(0, 10)
    courses = courses.filter(course => !course.hidden).slice(0, 10)
    products = products.filter(product => !product.hidden).slice(0, 10)

    useEffect(() => {
        initGA();
        logPageView();
        dispatch(getRecipes());
        dispatch(getCourses());
        dispatch(getReviews());
        dispatch(getProducts());
        dispatch(getPromos());

        if (recipeError) {
            dispatch(clearRecipeErrors())
        }
        if (courseError) {
            dispatch(clearCourseErrors())
        }
        if (reviewError) {
            dispatch(clearReviewErrors())
        }
        if (productError) {
            dispatch(clearProductErrors())
        }
        if (promoError) {
            dispatch(clearPromoErrors())
        }

    }, [dispatch, recipeError, courseError, productError, promoError]);

  
    const sendEmail = () => {
      const mailtoUrl = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.open(mailtoUrl);
    };

  return (
    <Fragment>
        <Metadata title={'Academy'} />
        <CarouselComponent images={["academy_bg.png", "recipes_bg.png", "masterclass_bg.png", "shop_bg.png"]}/>
        <div className="container-fluid container-md">

            <div className="text-center my-3 my-md-5 pt-4">
                <YouTubeVideo videoId="JuLjEDC7EMY" />
            </div>

            <div className="text-center my-5">
                <h2 className="text-lg">ABOUT OMOYE</h2>

                <hr className="mx-auto w-25" />

                <p className="text-xs my-4 spaced">Omoye was born in Lagos, Nigeria. 
                    Being raised in a culturally diverse state had a profound effect on her passion for cooking. 
                    One of the most beautiful memories of her childhood was the community of women who would gather together to cook for special occasions. 
                    This experience instilled in Omoye a love for culinary arts and a desire to share it with others.
                </p>
                <div className="d-flex justify-content-center">
                    <Link to="/about-omoye" className="carousel-btn-border py-3 mt-3 me-2 text-xxs bold-2 white">Learn more</Link>
                    <button onClick={sendEmail} className="carousel-btn py-3 mt-3 border rounded text-xxs bold-2 white">Contact Omoye</button>
                </div>
                
            </div>
        </div>


        <div className="container-fluid container-md">
            {/* RECIPES */}
            <div className="text-center mb-5">
                <h2 className="text-lg">LATEST RECIPES FROM OMOYE</h2>
                <hr className="mx-auto w-25" />
                <p className="text-xs mt-4 mb-5 spaced">OmoyeCooks recipes are famous for creativity that transforms a regular meal to a super delicacy. 
                Now you have a collection to choose from and “wow” your friends & family every meal time.</p>

                { recipeLoading ? 
                    <div className="my-4">
                        <Skeleton count={1} height={300} baseColor="#dc720082"/>
                    </div> :
                    <div className="wrapper">
                        { recipes.map(recipe => (
                            <Link to={`/recipes/${recipe.name}`} key={recipe._id}  className="wrapper-box shadow-sm">
                                <div className="recipe-img-sm bg-white" style={{backgroundImage: `url(${recipe.image.URL})`, backgroundPosition: "center", backgroundSize: "cover"}}></div>

                                <div className="text-start px-2 pb-4">
                                    <p className="p-ellipsis text-xxs text-muted mt-3 mb-0">{recipe.category.toUpperCase()}</p>
                                    <p className="p-ellipsis text-sm bold-1 mt-1 mb-0">{recipe.name}</p>
                                    <p className="p-ellipsis text-xs mt-1 mb-0">{recipe.description}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                }
            </div>

            {/* COURSES */}
            <div className="text-center mb-5">
                <h2 className="text-lg">LATEST COURSES FROM OMOYE</h2>
                <hr className="mx-auto w-25" />
                <p className="text-xs mt-4 mb-5 spaced">We have carefully crafted out cooking classes to suit your needs. 
                Each class contains detailed videos on how to make the outlined meals. The videos are so detailed and come with access to telegram chat groups for questions and answers. 
                All we ask is that you come ready and you’ll be glad you signed up.
                </p>
                {courseLoading ?
                    <div className="my-4">
                        <Skeleton count={1} height={300} baseColor="#dc720082"/>
                    </div> :
                    <div className="wrapper">
                        { courses.map(course => (
                            <CourseCard key={course._id} course={course} activePromotions={activePromotions}/>
                        ))}
                    </div>
                }
            </div>

            
            {/* PRODUCTS */}
            <div className="text-center mb-5">
                <h2 className="text-lg">BEST SELLING PRODUCTS FROM OMOYE SHOP</h2>
                <hr className="mx-auto w-25" />
                <p className="text-xs mt-4 mb-5 spaced">Browse our spices, cookbooks and other products to spice up your cooking and bring that rich goodness into every meal.
                </p>
                {productLoading ?
                    <div className="my-4">
                        <Skeleton count={1} height={300} baseColor="#dc720082"/>
                    </div> :
                    <div className="wrapper">
                        { products.map(product => (
                            <ProductCard key={product._id} product={product} activePromotions={activePromotions} className="wrapper-box"/>
                        ))}
                    </div>
                }
            </div>

            {/* COMMENTS */}
            <div className="text-center mb-5">
                <h2 className="text-lg">WHAT OUR STUDENTS ARE SAYING</h2>
                <hr className="mx-auto w-25" />
                <p className="text-xs mt-4 mb-5 spaced">Here are some comments from our students on some of the courses they have taken so far. We value all the feedback and improve our courses based on feedback.
                </p>
            </div>

            {reviewLoading ? 
                <div className="my-4">
                    <Skeleton count={1} height={300} baseColor="#dc720082"/>
                </div> :
                <div className="wrapper mt-3 mb-5">
                { courseReviews.map(review => (
                    <div className="review-box home-box d-flex flex-column justify-content-between" key={review._id}>
                        <div>
                            <h5 className="text-xs text-muted p-ellipsis"><i>{review.user.firstName} {review.user.lastName} says...</i></h5>
                            <p className="spaced text-xs bold-2 mb-0 p-ellipsis">{review.header}</p>
                            <ul className="stars p-0 mb-2">
                                <li className="star text-md"><i className={`fa fa-star ${review.rating >= 1 ? "orange": ""}`}></i></li>
                                <li className="star text-md mx-1"><i className={`fa fa-star ${review.rating >= 2 ? "orange": ""}`}></i></li>
                                <li className="star text-md"><i className={`fa fa-star ${review.rating >= 3 ? "orange": ""}`}></i></li>
                                <li className="star text-md mx-1"><i className={`fa fa-star ${review.rating >= 4 ? "orange": ""}`}></i></li>
                                <li className="star text-md"><i className={`fa fa-star ${review.rating >= 5 ? "orange": ""}`}></i></li>
                            </ul>
                            <p className="spaced review-comment text-xs mb-0">{review.comment}</p>
                        </div>

                        <i className="d-block text-xxs bold-2 text-muted p-ellipsis">{review.itemName}</i>
                    </div>
                ))}
                </div>
            }
        </div>    

        {/* UPCOMING */}
        <div className="upcoming bg-orange white px-3 py-4 px-lg-5 py-lg-5 shadow-sm">
            <h5 className="text-xs">UPCOMING EVENTS</h5>
            <hr className="w-25"/>
            <div>
                <p className="text-xs bold-2">New Cooking Series Launch: "From My Kitchen To Yours"</p>
                <p className="text-xxs">Catch up on weekly episodes on YouTube. Make sure to subscribe and turn on notifications so you don’t miss anything.</p>
            </div>
            <hr className="w-25"/>
            <div>
                <p className="text-xs bold-2 mt-3">New cookbook launch</p>
                <p className="text-xxs">Coming in 2023</p>
            </div>

        </div>              
    </Fragment>
  )
}

export default Home