import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { numberWithCommas } from '../../Functions/functions';


const CourseCard = ({ course, activePromotions }) => {
    
  // Function to calculate the discounted price based on active promotions
    const calculateDiscountedPrice = () => {
    let price = course?.price

    const applicablePromotions = activePromotions.filter(promo => {
        const targetValues = promo.targetValues.map(item => item.value); // Extract values from the array of objects
      
        return (
          (promo.targetType === 'All courses' || targetValues.includes(course?._id)) && promo.promoType === 'Percentage'
        );
      });

    if (applicablePromotions.length > 0) {
        const maxDiscount = Math.max(...applicablePromotions.map(promo => promo.discountValue));
        const discountPercentage = maxDiscount / 100;
        return price - (price * discountPercentage);
    }

    return price;
    };

    const discountedPrice = calculateDiscountedPrice();

    return (
    <Fragment>
        <Link to={`/academy/${course?._id}`}  key={course?._id} state={{discountedPrice}}  className="wrapper-box shadow-sm">
            <div className="course-vid bg-white" style={{backgroundImage: `url('${course?.prevVideo?.thumbnailURL}')`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
            <div className="text-start px-2 pb-4">
                <p className="p-ellipsis text-sm bold-1 mt-3 mb-0">{course?.name}</p>
                {discountedPrice !== course?.price ? 
                    <p className="text-sm bold-2 mt-1 mb-0">&#x20A6;{numberWithCommas(discountedPrice)} <span className="bold-1 text-muted text-xs text-decoration-line-through">&#x20A6;{numberWithCommas(course?.price)}</span></p>
                :   <p className="text-sm bold-2 mt-1 mb-0">&#x20A6;{numberWithCommas(course?.price)}</p>
                }
                <p className="text-xs mt-1 mb-0">{course?.duration}<span className="green bold-2">{course?.courseVideos.filter(video => video.unlocked).length > 0 ? " • Active" : ""}</span></p>
            </div>
        </Link>
    </Fragment>
    );
}

export default CourseCard;

