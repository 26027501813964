import './App.css';
import './Shop.css';
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import ProtectedRoute from './components/route/ProtectedRoute'

import Home from './components/home'

// ADMIN
import Dashboard from './components/admin/dashboard'
import Courses from './components/admin/courses'
import AddCourse from './components/admin/addCourse'
import AddVideos from './components/admin/addVideos'
import ChangeVideos from './components/admin/changeVideos'
import UpdateCourse from './components/admin/updateCourse'
import CourseDetails from './components/admin/courseDetails'
import Classes from './components/admin/classes'
import AddClass from './components/admin/addClass'
import ClassDetails from './components/admin/classDetails'
import Recipes from './components/admin/recipes'
import AddRecipe from './components/admin/addRecipe'
import UpdateRecipe from './components/admin/updateRecipe'
import AdminRecipeDetails from './components/admin/recipeDetails'
import Payments from './components/admin/payments'
import Users from './components/admin/users'
import UserDetails from './components/admin/userDetails'
import Products from './components/admin/products'
import AddProduct from './components/admin/addProduct'
import UpdateProduct from './components/admin/updateProduct'
import ProductDetails from './components/admin/productDetails'
import AllPromos from './components/admin/allPromos'
import AddPromo from './components/admin/addPromo'
// import PromoDetails from './components/admin/promoDetails'
import AllOrders from './components/admin/allOrders'
import AdminOrderDetails from './components/admin/adminOrderDetails'

//LAYOUT
import Header from './components/layout/header'
import ShopHeader from './components/layout/shopHeader'
import Footer from './components/layout/footer'
import ComingSoon from './components/layout/comingSoon'

// MASTERCLASS
import Masterclass from './components/masterclass/masterclass'

// ABOUT
import AboutOmoye from './components/about/aboutOmoye'

// ACADEMY
import Academy from './components/academy/academy'
import UserCourseDetails from './components/academy/userCourseDetails'

// RECIPE
import UserRecipes from './components/recipe/recipe'
import RecipeDetails from './components/recipe/recipeDetails'

// SHOP
import ShopHome from './components/shop/home'
import ProductCategories from './components/shop/productCategories'
import ShopProductDetails from './components/shop/productDetails'
import Cart from './components/shop/cart'
import Shipping from './components/shop/shipping'
import AddShippingAddress from './components/shop/addShippingAddress'
import ConfirmOrder from './components/shop/confirmOrder'
import Payment from './components/shop/payment'
import OrderSuccess from './components/shop/orderSuccess'
import BuyerOrders from './components/shop/buyerOrders'
import BuyerEbooks from './components/shop/buyerEbooks'
import OrderDetails from './components/shop/orderDetails'

// USER
import Signin from './components/user/signin'
import Register from './components/user/register'
import Welcome from './components/user/welcome'
import UserProfile from './components/user/userProfile'
import ChangePassword from './components/user/changePassword'
import ForgotPassword from './components/user/forgotPassword'
import ResetPassword from './components/user/resetPassword'

import { loadUser } from './actions/userActions'
import store from './store'

import { initGA, logPageView } from './GoogleAnalytics';

function App() {

  const location = useLocation();
  const isShopRoute = location.pathname.startsWith('/shop');

  useEffect(() => {
    store.dispatch(loadUser());
    initGA();
    logPageView();
  }, []);

  return (
    <div className="App">
      { isShopRoute ? <ShopHeader /> : <Header /> }

      <Routes>
        <Route path="/coming-soon" element={<ComingSoon />} />
        {/* ADMIN */}
        <Route path="/admin/dashboard" element={<ProtectedRoute isAdmin={true}><Dashboard /></ProtectedRoute>} />
        <Route path="/admin/academy" element={<ProtectedRoute isAdmin={true}><Courses /></ProtectedRoute>} />
        <Route path="/admin/academy/add-course" element={<ProtectedRoute isAdmin={true}><AddCourse /></ProtectedRoute>} />
        <Route path="/admin/academy/:id/add-videos" element={<ProtectedRoute isAdmin={true}><AddVideos /></ProtectedRoute>} />
        <Route path="/admin/academy/:id/change-videos" element={<ProtectedRoute isAdmin={true}><ChangeVideos /></ProtectedRoute>} />
        <Route path="/admin/academy/:id" element={<ProtectedRoute isAdmin={true}><CourseDetails /></ProtectedRoute>} />
        <Route path="/admin/academy/:id/update-course" element={<ProtectedRoute isAdmin={true}><UpdateCourse /></ProtectedRoute>} />

        <Route path="/admin/classes" element={<ProtectedRoute isAdmin={true}><Classes /></ProtectedRoute>} />
        <Route path="/admin/classes/add-class" element={<ProtectedRoute isAdmin={true}><AddClass /></ProtectedRoute>} />
        <Route path="/admin/classes/:id" element={<ProtectedRoute isAdmin={true}><ClassDetails /></ProtectedRoute>} />

        <Route path="/admin/recipes" element={<ProtectedRoute isAdmin={true}><Recipes /></ProtectedRoute>} />
        <Route path="/admin/recipes/add-recipe" element={<ProtectedRoute isAdmin={true}><AddRecipe /></ProtectedRoute>} />
        <Route path="/admin/recipes/:name" element={<ProtectedRoute isAdmin={true}><AdminRecipeDetails /></ProtectedRoute>} />
        <Route path="/admin/recipes/:name/update-recipe" element={<ProtectedRoute isAdmin={true}><UpdateRecipe /></ProtectedRoute>} />

        <Route path="/admin/users" element={<ProtectedRoute isAdmin={true}><Users /></ProtectedRoute>} />
        <Route path="/admin/users/:id" element={<ProtectedRoute isAdmin={true}><UserDetails /></ProtectedRoute>} />

        <Route path="/admin/products" element={<ProtectedRoute isAdmin={true}><Products /></ProtectedRoute>} />
        <Route path="/admin/products/add-product" element={<ProtectedRoute isAdmin={true}><AddProduct /></ProtectedRoute>} />
        <Route path="/admin/products/:id" element={<ProtectedRoute isAdmin={true}><ProductDetails /></ProtectedRoute>} />
        <Route path="/admin/products/:id/update-product" element={<ProtectedRoute isAdmin={true}><UpdateProduct /></ProtectedRoute>} />
        
        <Route path="/admin/promos" element={<ProtectedRoute isAdmin={true}><AllPromos /></ProtectedRoute>} />
        <Route path="/admin/promos/add-promo" element={<ProtectedRoute isAdmin={true}><AddPromo /></ProtectedRoute>} />
        {/* <Route path="/admin/promos/:id" element={<ProtectedRoute isAdmin={true}><PromoDetails /></ProtectedRoute>} /> */}

        <Route path="/admin/orders" element={<ProtectedRoute isAdmin={true}><AllOrders /></ProtectedRoute>} />
        <Route path="/admin/orders/:id" element={<ProtectedRoute isAdmin={true}><AdminOrderDetails /></ProtectedRoute>} />

        <Route path="/admin/payments" element={<ProtectedRoute isAdmin={true}><Payments /></ProtectedRoute>} />
        {/* <Route path="/admin/users/:id" element={<ProtectedRoute isAdmin={true}><UserDetails /></ProtectedRoute>} /> */}
        
        <Route path="/" element={<Home />} />
        <Route path="/about-omoye" element={<AboutOmoye />} />
        <Route path="/academy" element={<Academy />} />
        <Route path="/academy/:id" element={<UserCourseDetails />} />
        <Route path="/masterclass" element={<Masterclass />} />
        <Route path="/recipes" element={<UserRecipes />} />
        <Route path="/recipes/:name" element={<RecipeDetails />} />
        
        {/* USER */}
        <Route path="/signin" element={<Signin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
        <Route path="/password/forgot" element={<ForgotPassword />} />
        <Route path="/password/reset/:token" element={<ResetPassword />} />
        <Route path="/verify/:confirmationCode" element={<Welcome />} />
        <Route path="/password/change" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />

        {/* SHOP */}
        <Route path="/shop" element={<ShopHome />} />
        <Route path="/shop/:category" element={<ProductCategories />} />
        <Route path="/shop/:category/:id" element={<ShopProductDetails />} />
        <Route path="/shop/cart" element={<Cart />} />

        <Route path="/shop/checkout/shipping" element={<ProtectedRoute><Shipping /></ProtectedRoute>} />
        <Route path="/shop/checkout/shipping/add-address" element={<ProtectedRoute><AddShippingAddress /></ProtectedRoute>} />
        <Route path="/shop/checkout/confirm-order" element={<ProtectedRoute><ConfirmOrder /></ProtectedRoute>} />
        <Route path="/shop/checkout/payment" element={<ProtectedRoute><Payment /></ProtectedRoute>} />
        <Route path="/shop/checkout/success" element={<ProtectedRoute><OrderSuccess /></ProtectedRoute>} />

        <Route path="/shop/my-orders" element={<ProtectedRoute><BuyerOrders /></ProtectedRoute>} />
        <Route path="/shop/my-orders/:id" element={<ProtectedRoute><OrderDetails /></ProtectedRoute>} />
        <Route path="/shop/my-ebooks" element={<ProtectedRoute><BuyerEbooks /></ProtectedRoute>} />
      
      </Routes>

      <Footer/>
    </div>
  );
}

function Main() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default Main;